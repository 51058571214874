import React, { useState, useRef } from 'react';
import './App.css';
import backgroundMusic from './components/audio.mp3';
import image1 from './components/image1.jpg';
import image2 from './components/image2.jpg';
import image3 from './components/image3.jpg';
import image4 from './components/image4.jpg';
import image5 from './components/image5.jpg';
import image6 from './components/image6.jpg';
import image7 from './components/image7.jpg';
import image8 from './components/image8.jpg';
import image9 from './components/image9.jpg';
import image10 from './components/image10.jpg';
import image11 from './components/image11.jpg';
import image12 from './components/image12.jpg';
import image13 from './components/image13.jpg';
import image14 from './components/image14.jpg';
import image15 from './components/image15.jpg';
import image16 from './components/image16.jpg';
import image17 from './components/image17.jpg';
import image18 from './components/image18.jpg';
import image19 from './components/image19.jpg';
import image20 from './components/image20.jpg';
import image21 from './components/image21.jpg';
import image22 from './components/image22.jpg';
import image23 from './components/image23.jpg';
import image24 from './components/image24.jpg';


function App() {
  const [lineOne, setLineOne] = useState('');
  const [lineTwo, setLineTwo] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [showRefresh, setShowRefresh] = useState(false);
  const audioRef = useRef(null);
  const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9,
    image10, image11, image12, image13,
    image14, image15, image16, image17, image18, image19, image20,
    image21, image22, image23, image24];
  // Define the typing speed in milliseconds
  const typingSpeed = 110; // Faster typing has a smaller number, slower typing has a larger number

  const typeTextLineOne = " Mai baby love you so much forever you and I.";
  const typeTextLineTwo = "I  love you oh I love you so much forever you and I.";

  const typeText = (text, setTextCallback, onComplete) => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length) {
        setTextCallback((prev) => prev + text.charAt(index));
        index++;
      } else {
        clearInterval(interval);
        if (onComplete) {
          onComplete();
        }
      }
    }, typingSpeed);
  };

  const startTyping = () => {
    setIsPlaying(true);
    setTimeout(() => { // Delay the typing start by 2.2 seconds
      typeText(typeTextLineOne, setLineOne, () => {
        typeText(typeTextLineTwo, setLineTwo, () => {
          setShowRefresh(true); // Show the refresh button when typing is done
        });
      });
    }, 2200);
  };

  const togglePlay = () => {
    if (!isPlaying) {
      audioRef.current.play();
      startTyping();
      startFallingItems();
    }
  };
  const getRandomImage = () => {
    return images[Math.floor(Math.random() * images.length)];
  };

  const startFallingItems = () => {
    setInterval(() => {
      if (Math.random() < 0.63) {
        createFallingHeart();
      } else {
        createFallingImage();
      }
    }, 500);
  };

  const createFallingHeart = () => {
    const heart = document.createElement('div');
    heart.innerHTML = '❤️';
    heart.className = 'falling-item heart';
    document.body.appendChild(heart);
    setupFallingItem(heart);
  };

  const createFallingImage = () => {
    const image = new Image();
    image.src = getRandomImage();
    image.className = 'falling-item image';
    document.body.appendChild(image);
    setupFallingItem(image);
  };

  const setupFallingItem = (element) => {
    element.style.left = `${Math.random() * 100}vw`;
    element.style.transform = `rotate(${Math.random() * 20 - 10}deg)`; // Random angle
    setTimeout(() => {
      element.remove();
    }, 10000);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="App">
      <div className="center-text">
        <p>{lineOne}</p>
        <p>{lineTwo}</p>
      </div>
      <audio ref={audioRef} loop>
        <source src={backgroundMusic} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      {!isPlaying && (
        <button onClick={togglePlay} className="audio-control">
          Play
        </button>
      )}
      {showRefresh && (
        <button onClick={refreshPage} className="audio-control refresh">
          Refresh
        </button>
      )}
    </div>
  );
}


export default App;
